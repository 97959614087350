<template>
	<div>
		<div :class="name == '赛事报名' ? 'head1':(name == '成绩查询' ? 'head2':(name == '最高纪录' ? 'head3':'head'))"
			:style="{height: type != 1 ? '120PX':''}">
			<div class="header-box">
				<div class="logoback" @click="getfh">
					<img src="../image/fh.png" alt="">
				</div>
				<div class="input">
					<img src="../image/searchfor.png" alt="">
					<input type="text" placeholder="请输入您想查询的赛事" v-model="keyword" @keyup.enter="submit"
						@focus="getfocus">
					<div class="sous" @click="submit" v-if="show">搜索</div>
				</div>
				<el-dropdown trigger="click">
					<span class="el-dropdown-link">
						<img src="../image/more.png" alt="" class="more">
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item><a href="/h5">首 页</a></el-dropdown-item>
						<el-dropdown-item v-if="this.$store.state.token != ''">
							<div @click="determine" v-if="this.$store.state.token != '' && this.$store.state.userType == 'user_type_01'">
								<span>欢迎您,</span>
								<span class="roundtitle" style="color: #0047A5;" v-if="this.$store.state.realnameornot.requeststatus == 1 || this.$store.state.realnameornot.caStatus == 1">{{this.$store.state.realnameornot.realname}}</span>
								<span class="roundtitle" style="color: #0047A5;" v-else>{{this.$store.state.user.phone}}</span>
							</div>
							<div @click="determine" v-if="this.$store.state.token != '' && this.$store.state.userType == 'user_type_02'">
								<span>欢迎您,</span>
								<span class="roundtitle" style="color: #0047A5;" v-if="groupdate.requeststatus == 1">{{groupdate.name}}</span>
								<span class="roundtitle" style="color: #0047A5;" v-else>{{this.$store.state.user.phone}}</span>
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div v-if="this.$store.state.token == ''" @click="center">
								用户中心
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div v-if="this.$store.state.token == ''">
								<a href="/h5/register">账号注册</a>
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div v-if="this.$store.state.token != ''" @click="exit">
								退 出
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div ref="warapper" class="warapper"
				:style="{width: width,'margin-top':name == '赛事报名' || name == '成绩查询' ||name == '最高纪录' ? '132PX':'15PX' }"
				v-if="date && date.list && date.list.length > 0 && type == 1">
				<ul class="ul" ref="navList">
					<li v-for="(item,index) in date.list[0].children" :key="index"
						:class="item.id == id ? 'li selected':'li'" @click="getwara(item)">{{item.name}}</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		props: {
			date: {
				type: Object,
				default: () => {}
			},
			type: {
				type: Number,
				default: 1
			},
			title: {
				type: String,
				default: ''
			}
		},
		computed: {
			width() {
				return Number(document.documentElement.clientWidth)
			}
		},
		watch: {
			date() {
				if (this.date) {
					this.id = this.date.pid.id
					this.$nextTick(() => {
						// this.scrollToSelectedNavItem()
						for (let i in this.date.list[0].children) {
							if (this.date.list[0].children[i].name == this.title) {
								const targetObject = this.date.list[0].children.splice(i, 1)[0];
								this.date.list[0].children.unshift(targetObject)
							}
						}
					})

				}
			}
		},
		data() {
			return {
				id: '',
				name: this.title,
				keyword: '',
				show: false,
				groupdate:{}
			}
		},
		created() {
			if(this.$store.state.userType == 'user_type_02' && this.$store.state.token != ''){
				this.getCommunityAuthenInfo()
			}
			if(this.$store.state.userType == 'user_type_01' && this.$store.state.token != ''){
				this.$store.commit("EXIT_REALN")
			}
			this.$getwxsdk(this.name + '-北京市体育竞赛管理和国际交流中心')
		},
		mounted() {
			
		},
		methods: {
			getCommunityAuthenInfo(){
				this.$api.getCommunityAuthenInfo({userid:this.$store.state.userid}).then(res=>{
					this.groupdate = res.data.data.result
				})
			},
			scrollToSelectedNavItem() {
				const navList = this.$refs.navList;
				const selectedNavItem = navList.querySelector('.selected');
				selectedNavItem.scrollIntoView({
					behavior: 'smooth', // 平滑滚动
					block: 'nearest', // 将选中的元素尽可能滚动到可见区域
					inline: 'center' // 将选中的元素水平居中滚动到可见区域
				});
			},
			getwara(item) {
				if (item.name == '赛事登记') {
					if (this.$store.state.token == '') {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/h5/login',
							query: {
								person: false,
								refresh: new Date().getTime()
							}
						})
						return
					}
					if (this.$store.state.userType == 'user_type_01') {
						this.$confirm('当前账号为个人账号，是否切换成团体账号', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/h5/login',
								query: {
									person: false,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => {});

						return

					}
					this.$api.getTimeStamp().then(ess=>{
						let data = {
							accessToken: this.$store.state.token,
							redirectURL: '/sportMatch/SportMatchList',
							timestampe:ess.data.data.timestamp
						}
						this.$api.getCode(data).then(err => {
							let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data.data.result.code
							window.open(url, '_self');
						})
					})
				} else if(item.linkAddress){
					window.open(item.linkAddress, '_self');
				} else {
					if(item.name == '精彩瞬间'){
						this.id = item.id
						let data = {
							id: 728,
							template: 'picture_column',
							name: '精彩瞬间',
							parentId: 720
						}
						this.$emit('getwara', data)
						this.name = item.name
					}else{
						this.id = item.id
						let data = {
							id: item.id,
							template: item.template,
							name: item.name,
							parentId: item.parentId
						}
						this.$emit('getwara', data)
						this.name = item.name
					}
				}
				
				this.$getwxsdk(item.name + '-北京市体育竞赛管理和国际交流中心')
			},
			getfh() {
				this.$router.go(-1)
			},
			exit() {
				this.$api.checkLogout({
					accessToken: this.$store.state.token
				}).then(res => {
					this.$message.success(res.data.data.message);
					setTimeout(() => {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/h5/login'
						})
					}, 500)
				})
			},
			center() {
				if (this.$store.state.token == '') {
					this.$router.push({
						path: '/h5/login'
					})
				} else {
					this.$router.push({
						path: '/h5/register'
					})
				}
			},
			determine() {
				if (this.$store.state.userType == 'user_type_01') {
					this.$router.push({
						path: '/h5/registerIndex',
						query: {
							refresh: new Date().getTime()
						},
					})
				} else {
					this.$api.getTimeStamp().then(ess=>{
						let data = {
							accessToken: this.$store.state.token,
							redirectURL: '/authIndex',
							timestampe:ess.data.data.timestamp
						}
						this.$api.getCode(data).then(err => {
							let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data.data.result.code
							window.open(url, '_self');
						})
					})
				}
			},
			submit() {
				this.$router.push({
					path: '/h5/search',
					query: {
						keyword: this.keyword,
						refresh: new Date().getTime()
					},
				})
				this.show = false
			},
			getfocus() {
				this.show = true
			},
			getblur() {
				this.show = false
			}
		}
	}
</script>

<style scoped lang="scss">
	.head {
		width: 100%;
		height: 150PX;
		background: url('../image/head_back.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.header-box {
			display: flex;
			justify-content: space-between;

			.logoback {
				width: 9PX;
				height: 17PX;
				margin-top: 67PX;
				margin-left: 14PX;
				flex-shrink: 0;

				img {
					width: 9PX;
					height: 17PX;
				}
			}
		}

		.input {
			width: 77%;
			height: 36PX;
			background: #FFFDFD;
			border-radius: 18PX;
			margin-top: 60PX;
			margin-left: 14PX;
			display: flex;
			align-items: center;


			img {
				width: 15PX;
				height: 15PX;
				margin-left: 31PX;
				margin-right: 15PX;
			}

			input {
				width: 77%;
				height: 36PX;
				border-radius: 18PX;
				font-size: 12PX;
			}

			.sous {
				flex-shrink: 0;
				color: #fff;
				height: 26PX;
				padding: 0 15PX;
				border-radius: 20PX;
				background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
				margin-right: 5PX;
				line-height: 26PX;
			}
		}

		.more {
			width: 16PX;
			height: 3PX;
			// margin-top: 79PX;
			margin-left: 12PX;
			margin-right: 17PX;
		}

		.el-dropdown-link{
			position: relative;
			top: 69PX;
		}
	}

	.head1 {
		width: 100%;
		height: 270PX;
		background: url('../image/application.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.header-box {
			display: flex;
			justify-content: space-between;

			.logoback {
				width: 9PX;
				height: 17PX;
				margin-top: 67PX;
				margin-left: 14PX;
				flex-shrink: 0;

				img {
					width: 9PX;
					height: 17PX;
				}
			}
		}

		.input {
			width: 77%;
			height: 36PX;
			background: #FFFDFD;
			border-radius: 18PX;
			margin-top: 60PX;
			margin-left: 14PX;
			display: flex;


			img {
				width: 15PX;
				height: 15PX;
				margin-top: 11PX;
				margin-left: 31PX;
				margin-right: 15PX;
			}

			input {
				width: 77%;
				height: 36PX;
				border-radius: 18PX;
				font-size: 12PX;
			}
		}

		.more {
			width: 16PX;
			height: 3PX;
			// margin-top: 79PX;
			margin-left: 12PX;
			margin-right: 17PX;
		}
		.el-dropdown-link{
			position: relative;
			top: 69PX;
		}
	}

	.head2 {
		width: 100%;
		height: 270PX;
		background: url('../image/query.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.header-box {
			display: flex;
			justify-content: space-between;

			.logoback {
				width: 9PX;
				height: 17PX;
				margin-top: 67PX;
				margin-left: 14PX;
				flex-shrink: 0;

				img {
					width: 9PX;
					height: 17PX;
				}
			}
		}

		.input {
			width: 77%;
			height: 36PX;
			background: #FFFDFD;
			border-radius: 18PX;
			margin-top: 60PX;
			margin-left: 14PX;
			display: flex;


			img {
				width: 15PX;
				height: 15PX;
				margin-top: 11PX;
				margin-left: 31PX;
				margin-right: 15PX;
			}

			input {
				width: 77%;
				height: 36PX;
				border-radius: 18PX;
				font-size: 12PX;
			}
		}

		.more {
			width: 16PX;
			height: 3PX;
			// margin-top: 79PX;
			margin-left: 12PX;
			margin-right: 17PX;
		}
		.el-dropdown-link{
			position: relative;
			top: 69PX;
		}
	}

	.head3 {
		width: 100%;
		height: 270PX;
		background: url('../image/record.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.header-box {
			display: flex;
			justify-content: space-between;

			.logoback {
				width: 9PX;
				height: 17PX;
				margin-top: 67PX;
				margin-left: 14PX;
				flex-shrink: 0;

				img {
					width: 9PX;
					height: 17PX;
				}
			}
		}

		.input {
			width: 77%;
			height: 36PX;
			background: #FFFDFD;
			border-radius: 18PX;
			margin-top: 60PX;
			margin-left: 14PX;
			display: flex;


			img {
				width: 15PX;
				height: 15PX;
				margin-top: 11PX;
				margin-left: 31PX;
				margin-right: 15PX;
			}

			input {
				width: 77%;
				height: 36PX;
				border-radius: 18PX;
				font-size: 12PX;
			}
		}

		.more {
			width: 16PX;
			height: 3PX;
			// margin-top: 79PX;
			margin-left: 12PX;
			margin-right: 17PX;
		}
		.el-dropdown-link{
			position: relative;
			top: 69PX;
		}
	}

	.warapper {
		overflow-x: auto;
		margin-top: 15PX;

		ul {
			display: flex;
		}

		li {
			font-size: 15PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #fff;
			flex-shrink: 0;
			margin-left: 20PX;
			padding: 2PX 10PX;
		}

		.selected {
			background: url('../image/navback.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			font-size: 17PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			font-style: italic;
			color: #1679F2;
		}
	}


	.warapper::-webkit-scrollbar {
		display: none;
	}
	.el-dropdown-menu{
		z-index: 9999 !important;
	}
</style>